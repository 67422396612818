import React, { useEffect } from "react";
import ExcelJS from "exceljs";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { getexcelNetWorthdata } from "./segmentData";
import { StructureSummaryData } from "./cfData";
//>>import "./debtSummaryStyles.scss";
//>>import "./debtsummary.css";

const whiteBorderStyle = {
  top: { style: "thin", color: { argb: "FFFFFFFF" } },
  left: { style: "thin", color: { argb: "FFFFFFFF" } },
  bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
  right: { style: "thin", color: { argb: "FFFFFFFF" } },
};

export const GeneratePresentation = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);

  const ProcessComplete = async () => {
    setIsLoading(true);
    await CreateExcel();
    setIsLoading(false);

    props.onMappingUpdated3(true);
  };

  useEffect(() => {
    ProcessComplete();
  }, []);

  const renderExcelValueFieldCell = (cell, item) => {
    cell.font = { color: { argb: "FF000000" } };
    const commonBorderStyle = { style: "thin", color: { argb: "FF000000" } };

    if (["T", "X"].includes(item.LINETYPE)) {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" },
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
      };
    } else if (item.LINETYPE === "") {
      cell.border = {
        right: commonBorderStyle,
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    } else if (item.LINETYPE === "H") {
      cell.border = {
        right: commonBorderStyle,
        top: commonBorderStyle,
        bottom: commonBorderStyle,
      };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE6D180" },
      };
      cell.value = "";
      return;
    } else if (item.LINETYPE === "B") {
      cell.value = "";
      return;
    }

    cell.numFmt = "$#,##0;($#,##0)";
  };

  const renderExcelDescriptionFieldCell = (cell, item) => {
    const commonBorderStyle = { style: "thin", color: { argb: "FF000000" } };

    if (item.LINETYPE === "H") {
      cell.font = { color: { argb: "FFFFFFFF" } };
      cell.fill = {
        bold: true,
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF000000" },
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "X" || item.LINETYPE === "T") {
      cell.font = {
        bold: true,
        color: { argb: item.LINETYPE === "X" ? "FF000000" : "FF000000" },
      };
      cell.fill = {
        bold: true,
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD9D9D9" },
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "") {
      cell.border = {
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    }

    cell.value = item.DESCRIPTION;
  };

  const CreateExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Net Worth");

      worksheet.columns = [
        { header: "", width: 10 },
        {
          header: `Net Assets ${props.clientCode} - Description`,
          key: "DESCRIPTION",
          width: 41,
        },
        { header: "", key: "VALUEFIELD", width: 15 },
        { header: "", key: "VALUEFIELD1", width: 15 },
        { header: "", key: "VALUEFIELD2", width: 15 },
        { header: "", width: 10 },
        { header: "", width: 10 },
        { header: "", width: 10 },
        { header: "", width: 10 },
        { header: "", width: 10 },
        {
          header: ``,
          key: "DESCRIPTION_INV",
          width: 41,
        },
        { header: "", key: "VALUEFIELD_INV", width: 15 },
        { header: "", key: "VALUEFIELD1_INV", width: 15 },
        { header: "", key: "VALUEFIELD2_INV", width: 15 },
      ];

      const headerRow = worksheet.getRow(1);
      headerRow.eachCell((cell) => {
        cell.border = whiteBorderStyle;
        if (cell.col === 2 || cell.col === 7) {
          cell.font = {
            bold: true,
          };
        }
      });

      const dataResponse = await getexcelNetWorthdata(props.clientCode);
      const data = dataResponse.data;

      let currentRow = 2;

      if (Array.isArray(data)) {
        let foundInvestmentDetail = false;
        data.forEach((item) => {
          if (item.DESCRIPTION === "Investments Details") {
            foundInvestmentDetail = true;
          }
          if (foundInvestmentDetail) {
            if (item.DESCRIPTION === "Debt Details") {
              foundInvestmentDetail = false;
              return;
            }
            const row = worksheet.getRow(currentRow);
            row.getCell(11).value = item.DESCRIPTION;
            row.getCell(12).value = item.VALUEFIELD;
            yapplyDescriptionCellStyle(row.getCell(11), item);
            yapplyValueFieldCellStyle(row.getCell(12), item, 1);
            currentRow++;
          }
        });

        // Adding Structure Summary Data
        await addStructureSummaryData(worksheet, currentRow, props.clientCode);
        currentRow += 2; // Leave a gap after the structure summary

        let foundDebtDetail = false;
        data.forEach((item) => {
          if (item.DESCRIPTION === "Debt Details") {
            foundDebtDetail = true;
          }
          if (foundDebtDetail) {
            if (item.DESCRIPTION === "Investments Details") {
              foundDebtDetail = false;
              return;
            }
            const row = worksheet.getRow(currentRow);
            row.getCell(2).value = item.DESCRIPTION;
            row.getCell(3).value = item.VALUEFIELD;
            yapplyDescriptionCellStyle(row.getCell(2), item);
            yapplyValueFieldCellStyle(row.getCell(3), item, 1);
            currentRow++;
          }
        });
      }

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buf], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        `${props.clientCode}Presentation.xlsx`
      );

      MySwal.fire({
        icon: "success",
        title: "Excel File Created",
        text: `The file ${props.clientCode}Presentation.xlsx has been successfully created.`,
        customClass: { container: "high-z-index" },
      });
    } catch (error) {
      console.error(
        "An error occurred during the Excel file creation process:",
        error
      );
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue creating the Excel file.",
        customClass: { container: "high-z-index" },
      });
    }
  };

  const addStructureSummaryData = async (worksheet, startRow, clientCode) => {
    const dataResponse = await StructureSummaryData(clientCode);
    const data = dataResponse.data;

    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        const row = worksheet.getRow(startRow + index);
        row.getCell(2).value = item.DESCRIPTION;
        row.getCell(3).value = item.VALUEFIELD;
        applyDescriptionCellStyle(row.getCell(2), item);
        applyValueFieldCellStyle(row.getCell(3), item, 1);
      });
    }
  };

  const yapplyDescriptionCellStyle = (cell, rowData) => {
    let fill = { type: "pattern", pattern: "solid", fgColor: {} };
    let fontColor = {};

    switch (rowData.LINETYPE) {
      case "H":
        fill.fgColor.argb = "FF000000";
        fontColor = { argb: "FFFFFFFF" };
        break;
      case "X":
        fill.fgColor.argb = "FFD3D3D3";
        fontColor = { argb: "FF0000FF" };
        break;
      case "T":
        fill.fgColor.argb = "FFD3D3D3";
        fontColor = { argb: "FF000000" };
        break;
      default:
        fill.fgColor.argb = "FFFFFFFF";
        break;
    }
    if (rowData.DESCRIPTION === null || rowData.DESCRIPTION === "") {
      cell.style = {
        font: { bold: true, color: fontColor },
        fill: fill,
        border: {
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
        },
      };
    } else {
      if (
        rowData.LINETYPE === "H" ||
        rowData.LINETYPE === "T" ||
        rowData.LINETYPE === "X" ||
        rowData.LINETYPE === "Z"
      ) {
        cell.style = {
          font: { bold: true, color: fontColor },
          fill: fill,
          border: {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
          },
        };
      } else {
        cell.style = {
          font: { bold: false, color: fontColor },
          fill: fill,
          border: {
            right: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
          },
        };
      }
    }

    cell.value = rowData.DESCRIPTION || null;
  };

  const yapplyValueFieldCellStyle = (cell, rowData, column) => {
    let fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFFFF" },
    };

    if (
      rowData.DESCRIPTION === null ||
      rowData.DESCRIPTION === "" ||
      (rowData.LINETYPE === "H" &&
        column === 1 &&
        rowData.PRINTCOLUMN1 === "N") ||
      (rowData.LINETYPE === "H" &&
        column === 2 &&
        rowData.PRINTCOLUMN2 === "N") ||
      (rowData.LINETYPE === "H" &&
        column === 3 &&
        rowData.PRINTCOLUMN3 === "N") ||
      (rowData.LINETYPE === "X" && column === 2) ||
      (rowData.LINETYPE === "X" && column === 3)
    ) {
      cell.style = {
        border: {
          bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
          right: { style: "thin", color: { argb: "FFFFFFFF" } },
          left: { style: "thin", color: { argb: "FFFFFFFF" } },
        },
      };
      cell.value = null;
      return;
    }

    let valueField, stringField, printColumn;
    if (rowData.LINETYPE === "X" && column === 1) {
      fill.fgColor.argb = "FFD3D3D3";
    }

    if (column === 1 && rowData.PRINTCOLUMN1 === "N") {
      cell.value = null;
      return;
    } else if (column === 2 && rowData.PRINTCOLUMN2 === "N") {
      cell.value = null;
      return;
    } else if (column === 3 && rowData.PRINTCOLUMN3 === "N") {
      cell.value = null;
      return;
    }

    if (column === 1 && rowData.PRINTCOLUMN1 !== "N") {
      printColumn = rowData.PRINTCOLUMN1;
      valueField = rowData.VALUEFIELD;
      stringField = rowData.FIRSTSTRING;
    } else if (column === 2 && rowData.PRINTCOLUMN2 !== "N") {
      printColumn = rowData.PRINTCOLUMN2;
      valueField = rowData.VALUEFIELD2;
      stringField = rowData.SECONDSTRING;
    } else if (column === 3 && rowData.PRINTCOLUMN3 !== "N") {
      printColumn = rowData.PRINTCOLUMN3;
      valueField = rowData.VALUEFIELD3;
      stringField = rowData.THIRDSTRING;
    }

    if (rowData.LINETYPE === "H") {
      fill.fgColor.argb = "FFE6D180";
    } else if (printColumn === "N") {
      fill = {};
    }

    let numFmt = printColumn === "H" ? null : '"$"#,##0;"($"#,##0)';

    if (rowData.LINETYPE === "T") {
      fill.fgColor.argb = "FFD3D3D3";
      cell.style = {
        font: { bold: true },
        fill: fill,
        border: {
          top: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        },
        numFmt: numFmt,
      };
    } else {
      cell.style = {
        fill: fill,
        border: {
          top: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
        },
        numFmt: numFmt,
      };
    }

    if (printColumn === "H") {
      cell.value = stringField || null;
      cell.alignment = { horizontal: "center" };
    } else {
      cell.value = valueField;
    }
    if (
      rowData.LINETYPE !== "X" &&
      rowData.LINETYPE !== "T" &&
      rowData.LINETYPE !== "H" &&
      rowData.LINETYPE !== "Z"
    ) {
      cell.style.border = {
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
    } else {
      if (rowData.DESCRIPTION !== " " && rowData.DESCRIPTION !== null) {
        cell.style.border.right = {
          style: "thin",
          color: { argb: "FF000000" },
        };
      }
    }
  };

  const applyDescriptionCellStyle = (cell, item) => {
    cell.font = { color: { argb: "FF000000" } };
    const commonBorderStyle = { style: "thin", color: { argb: "FF000000" } };

    if (["T", "X"].includes(item.LINETYPE)) {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" },
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "") {
      cell.border = {
        right: commonBorderStyle,
        left: commonBorderStyle,
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    } else if (item.LINETYPE === "H") {
      cell.border = {
        right: commonBorderStyle,
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        left: commonBorderStyle,
      };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE6D180" },
      };
      return;
    } else if (item.LINETYPE === "B") {
      cell.value = "";
      return;
    }

    cell.numFmt = "$#,##0;($#,##0)";
  };

  const applyValueFieldCellStyle = (cell, item, column) => {
    const commonBorderStyle = { style: "thin", color: { argb: "FF000000" } };

    if (item.LINETYPE === "H") {
      cell.font = { color: { argb: "FFFFFFFF" } };
      cell.fill = {
        bold: true,
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF000000" },
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "X" || item.LINETYPE === "T") {
      cell.font = {
        bold: true,
        color: { argb: item.LINETYPE === "X" ? "FF000000" : "FF000000" },
      };
      cell.fill = {
        bold: true,
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD9D9D9" },
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "") {
      cell.border = {
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
    }

    if (item.LINETYPE === "H") {
      if (column === 1) {
        cell.value = item.HEADINGONE;
        return;
      }
      if (column === 2) {
        cell.value = item.HEADINGTWO;
        return;
      }
      if (column === 3) {
        cell.value = item.TOTALHEADING;
        return;
      }
    }
    if (item.LINETYPE === "B") {
      cell.value = "";
      return;
    }

    cell.numFmt = "$#,##0;($#,##0)";
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-container">
          <p>Building the Excel File, please wait &nbsp;&nbsp;</p>
          <FontAwesomeIcon icon={faSpinner} spin className="large-spinner" />
        </div>
      )}
    </>
  );
};
