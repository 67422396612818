import React from "react";
import { Chart, Series } from "devextreme-react/chart";
import ExcelJS from "exceljs";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/auth";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Export,
} from "devextreme-react/data-grid";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Button } from "devextreme-react/button";
import jsPDF from "jspdf";
import "devextreme-react/text-area";
import "devextreme/data/data_source";
//>>import "./app.scss";
import { EditBatch } from "./editBatch";
import { GenerateProgressExcel } from "./generateProgressExcel";
import { GenerateExcelFiles } from "./generateExcelFiles";
import {
  mystore5,
  mystoreGraph,
  relatedData,
  relatedData2,
  relatedData3,
  getexceldata,
} from "./segmentData";

class ProgressSummaryx extends React.Component {
  constructor(props) {
    super(props);
    this.MySwal = withReactContent(Swal);
    this.applyFilterTypes = [
      {
        key: "auto",
        name: "Immediately",
      },
      {
        key: "onClick",
        name: "On Button Click",
      },
    ];
    this.state = {
      currentRow: 0,
      filterValue: "90",
      selectedRowKeys: [],
      showFilterRow: false,
      showHeaderFilter: false,
      companyCode: 1,
      assetGroupsCodes: [],
      currentFilter: this.applyFilterTypes[0].key,
      isLoading: false,
      thisWidth: 580,
      showCurrentOnly: this.props.showPrior,
      selectedRowData: null,
      selectedRowData2: null,
      selectedRowData3: null,
      lastClickedRowId: null,
      selectedRowNumber: null,
      clickCount: 0,
      PieActive: false,
      counterFlash: 0,
      editBatchOn: false,
      EditExcelOn: false,
      ShowCreateClientPresentation: this.props.ShowCreateClientPresentation,
    };
  }

  CreateExcel = () => {
    this.setState({ EditExcelOn: true });
  };

  handleMappingUpdated = (value) => {
    this.setState({ editBatchOn: false });
  };

  handleMappingUpdated2 = (value) => {
    this.setState({ EditExcelOn: false });
  };

  EditBatch = () => {
    this.setState({ editBatchOn: true });
  };

  handleSelectionChanged(e) {
    this.setState({ selectedRowKeys: e.selectedRowKeys });
    if (e.selectedRowKeys.length > 0) {
      this.setState({ currentRow: e.selectedRowKeys[0] });
    }
  }

  handleEditingStart(e) {
    const rowToBeEdited = e.data;
    if (rowToBeEdited.LINETYPE !== "") {
      e.cancel = true;
    }
  }

  onCellPrepared = (e) => {
    e.cellElement.style.padding = "0px";
    if (
      e.rowType === "data" &&
      e.rowIndex === 0 &&
      e.column.dataField === "BANKNAME"
    ) {
      e.cellElement.style.fontWeight = "bold";
    }
  };

  ClearClick = () => {
    this.setState({ selectedRowData: null });
    this.setState({ selectedRowData2: null });
    this.setState({ selectedRowData3: null });
  };

  ClearPieClick = () => {
    this.setState((prevState) => ({
      PieActive: !prevState.PieActive,
      counterFlash: prevState.counterFlash + 1,
    }));
  };

  onRowPrepared(e) {}

  onRowClick = async (e) => {
    if (e && e.data) {
      try {
        const rowData = e.data;
        this.setState({ selectedRowNumber: rowData.ROWNUMBER });
        this.setState(
          (prevState) => {
            const newClickCount =
              prevState.clickCount >= 3 ? 1 : prevState.clickCount + 1;
            return { clickCount: newClickCount };
          },
          async () => {
            let dataForRelatedGrid;
            if (this.state.clickCount === 1) {
              dataForRelatedGrid = await relatedData(
                this.props.clientCode,
                rowData.CHANGEINNETWORTHID
              );
              this.setState({ selectedRowData: dataForRelatedGrid });
            } else if (this.state.clickCount === 2) {
              dataForRelatedGrid = await relatedData2(
                this.props.clientCode,
                rowData.CHANGEINNETWORTHID
              );
              this.setState({ selectedRowData2: dataForRelatedGrid });
            } else if (this.state.clickCount === 3) {
              dataForRelatedGrid = await relatedData3(
                this.props.clientCode,
                rowData.CHANGEINNETWORTHID
              );
              this.setState({ selectedRowData3: dataForRelatedGrid });
            }
          }
        );
      } catch (error) {
        console.error("Error fetching related data", error);
      }
    }
  };

  generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(7); // Set a smaller font size for the entire document
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGridRef.instance,
      customizeCell: ({ gridCell, pdfCell }) => {
        if (gridCell.rowType === "data") {
          // Apply formatting based on LINETYPE
          if (gridCell.data.LINETYPE === "H") {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#000000";
            pdfCell.textColor = "#FFFFFF";
          } else if (
            gridCell.data.LINETYPE === "X" ||
            gridCell.data.LINETYPE === "T"
          ) {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#D9D9D9";
            pdfCell.textColor = "#000000";
          } else if (gridCell.data.LINETYPE === "H") {
            pdfCell.backgroundColor = "#E6D180";
            pdfCell.textColor = "#000000";
          } else if (gridCell.data.LINETYPE === "B") {
            pdfCell.text = ""; // Render blank line
          }

          // Remove borders for all cells
          pdfCell.borderColor = "#FFFFFF";

          // Set smaller font size
          pdfCell.fontSize = 8;
        }
      },
    }).then(() => {
      doc.save("progress_summary.pdf");
    });
  };

  renderRelatedGrid() {
    const { selectedRowData } = this.state;
    if (!selectedRowData) {
      return null;
    }
    return (
      <div className="content-block2 dx-card responsive-paddings">
        <Button
          text="Edit Section"
          onClick={this.EditBatch}
          style={{
            width: "200px",
            height: "30px",
            marginTop: "2px",
            marginBottom: "10px",
            marginLeft: "15px",
          }}
        ></Button>
        <div className="custom-container">
          <DataGrid
            dataSource={selectedRowData}
            rowHeight={"10px"}
            onCellPrepared={this.onCellPrepared}
          >
            <Column
              dataField="CLIENTCODE"
              caption="Client Code"
              width={200}
              visible={false}
            />
            <Column
              dataField="BANKNAME"
              caption="Bank"
              width={100}
              visible={true}
            />
            <Column
              dataField="BANKACCOUNTDESCRIPTION"
              caption="Bank Account"
              width={150}
              visible={true}
            />
            <Column
              dataField="FPTRANSACTIONCODE"
              caption="Code"
              width={150}
              visible={false}
            />
            <Column
              dataField="TRANSACTIONDATE"
              caption="Date"
              width={100}
              visible={true}
            />
            <Column dataField="DESCRIPTION" caption="Description" />

            <Column dataField="DESCRIPTIONTWO" caption="Details" />
            <Column
              dataField="TRANSACTIONAMOUNT"
              caption="Amount"
              format={"$###,###,###"}
              alignment="right"
              width={200}
              visible={true}
            />
            <Column
              dataField="UNIQUEID"
              caption="ID"
              width={200}
              visible={false}
            />
          </DataGrid>
        </div>
      </div>
    );
  }

  renderRelatedGrid2() {
    const { selectedRowData2 } = this.state;
    if (!selectedRowData2) {
      return null;
    }
    return (
      <div className="content-block2 dx-card responsive-paddings">
        <Button
          text="Edit Section"
          onClick={this.EditBatch}
          style={{
            width: "200px",
            height: "30px",
            marginTop: "2px",
            marginBottom: "10px",
            marginLeft: "15px",
          }}
        ></Button>
        <div className="custom-container">
          <DataGrid
            dataSource={selectedRowData2}
            rowHeight={"10px"}
            onCellPrepared={this.onCellPrepared}
          >
            <Column
              dataField="CLIENTCODE"
              caption="Client Code"
              width={200}
              visible={false}
            />
            <Column
              dataField="BANKNAME"
              caption="Bank"
              width={100}
              visible={true}
            />
            <Column
              dataField="BANKACCOUNTDESCRIPTION"
              caption="Bank Account"
              width={150}
              visible={true}
            />
            <Column
              dataField="FPTRANSACTIONCODE"
              caption="Code"
              width={150}
              visible={false}
            />
            <Column
              dataField="TRANSACTIONDATE"
              caption="Date"
              width={100}
              visible={true}
            />
            <Column dataField="DESCRIPTION" caption="Description" />
            <Column dataField="DESCRIPTIONTWO" caption="Details" />
            <Column
              dataField="TRANSACTIONAMOUNT"
              caption="Amount"
              format={"$###,###,###"}
              alignment="right"
              width={200}
              visible={true}
            />
            <Column
              dataField="UNIQUEID"
              caption="ID"
              width={200}
              visible={false}
            />
          </DataGrid>
        </div>
      </div>
    );
  }

  renderRelatedGrid3() {
    const { selectedRowData3 } = this.state;
    if (!selectedRowData3) {
      return null;
    }
    return (
      <div className="content-block2 dx-card responsive-paddings">
        <Button
          text="Edit Section"
          onClick={this.EditBatch}
          style={{
            width: "200px",
            height: "30px",
            marginTop: "2px",
            marginBottom: "10px",
            marginLeft: "15px",
          }}
        ></Button>
        <div className="custom-container">
          <DataGrid
            dataSource={selectedRowData3}
            rowHeight={"10px"}
            onCellPrepared={this.onCellPrepared}
          >
            <Column
              dataField="CLIENTCODE"
              caption="Client Code"
              width={200}
              visible={false}
            />
            <Column
              dataField="BANKNAME"
              caption="Bank"
              width={100}
              visible={true}
            />
            <Column
              dataField="BANKACCOUNTDESCRIPTION"
              caption="Bank Account"
              width={150}
              visible={true}
            />
            <Column
              dataField="FPTRANSACTIONCODE"
              caption="Code"
              width={150}
              visible={false}
            />
            <Column
              dataField="TRANSACTIONDATE"
              caption="Date"
              width={100}
              visible={true}
            />
            <Column dataField="DESCRIPTION" caption="Description" />
            <Column dataField="DESCRIPTIONTWO" caption="Details" />
            <Column
              dataField="TRANSACTIONAMOUNT"
              caption="Amount"
              format={"$###,###,###"}
              alignment="right"
              width={200}
              visible={true}
            />
            <Column
              dataField="UNIQUEID"
              caption="ID"
              width={200}
              visible={false}
            />
          </DataGrid>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <style>
          {`
          .dx-datagrid-rowsview .dx-row {
            border: none !important;
          }

          .dx-datagrid-headers .dx-header-row {
            border: none !important;
          }

          .dx-datagrid-content .dx-cell {
            border: none !important;
          }
          .modal {
            display: none; 
            position: fixed; 
            z-index: 1; 
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            overflow: auto; 
            background-color: rgb(0,0,0); 
            background-color: rgba(0,0,0,0.4); 
          }

          .modal.show {
            display: block; 
          }

          .modal-content {
            background-color: #fefefe;
            padding: 20px;
            border: 1px solid #888;
            width: 80%; 
            max-width: 1000px;
            max-height: 80%;
            overflow-y: auto;
          }

          .close {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
          }

          .close:hover,
          .close:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
          }
        `}
        </style>
        {this.state.ShowCreateClientPresentation === true && (
          <>
            <div>
              <h3 style={{ fontWeight: "bold", marginLeft: "20px" }}>
                Change in Net Worth
              </h3>
            </div>
            <p></p>
          </>
        )}
        {this.state.isLoading && (
          <div className="spinner-container">
            {this.state.isLoading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50vh",
                }}
              >
                <p style={{ marginBottom: "10px", fontSize: "24px" }}>
                  Building the Excel File please wait...
                </p>
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{ fontSize: "48px" }}
                />
              </div>
            )}
          </div>
        )}
        {this.state.EditExcelOn !== true && (
          <>
            <Button
              text="Clear Selections"
              onClick={this.ClearClick}
              style={{
                width: "200px",
                height: "30px",
                marginTop: "2px",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            ></Button>
            {this.state.ShowCreateClientPresentation !== true && (
              <Button
                text="Create Excel"
                onClick={this.CreateExcel}
                style={{
                  width: "200px",
                  height: "30px",
                  marginTop: "2px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
              ></Button>
            )}
            {this.state.ShowCreateClientPresentation !== true && (
              <Button
                text="Generate PDF"
                onClick={this.generatePDF}
                style={{
                  width: "200px",
                  height: "30px",
                  marginTop: "2px",
                  marginBottom: "10px",
                  marginLeft: "15px",
                }}
              ></Button>
            )}
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <DataGrid
                  dataSource={mystore5(
                    this.props.clientCode,
                    this.state.ShowCreateClientPresentation
                  )}
                  onRowPrepared={this.onRowPrepared}
                  onCellPrepared={this.onCellPrepared}
                  remoteOperations={false}
                  onSelectionChanged={this.handleSelectionChanged.bind(this)}
                  onEditingStart={this.handleEditingStart}
                  id="yourGridId"
                  width={"70%"}
                  height={"100%"}
                  rowHeight={"10px"}
                  onRowClick={this.onRowClick}
                  showBorders={false}
                  showRowLines={false}
                  ref={(ref) => (this.dataGridRef = ref)}
                >
                  <FilterRow
                    visible={this.state.showFilterRow}
                    applyFilter={this.state.currentFilter}
                  />
                  <HeaderFilter visible={this.state.showHeaderFilter} />
                  <Paging enabled={false} />
                  <Column
                    dataField="UNIQUEID"
                    caption="Unique ID"
                    visible={false}
                  />
                  <Column dataField="ROWNUMBER" caption="Row" visible={false} />
                  <Column
                    dataField="DESCRIPTION"
                    caption=""
                    width={350}
                    visible={true}
                    cellRender={renderDescriptionCell}
                  />
                  <Column
                    dataField="VALUEFIELD"
                    caption=""
                    format={"$###,###,###"}
                    alignment="right"
                    cellRender={renderValueFieldCell}
                    width={150}
                  />
                  <Column
                    dataField="FORMULAFIELD"
                    caption="Formula"
                    visible={false}
                  />
                  <Column dataField="LINETYPE" caption="Type" visible={false} />
                  <Column dataField="UNIQUEID" caption="id" visible={false} />
                </DataGrid>
              </div>
              <div style={{ flex: 1 }}>
                {this.renderRelatedGrid()}
                {this.renderRelatedGrid2()}
                {this.renderRelatedGrid3()}
              </div>
            </div>
          </>
        )}

        {this.state.editBatchOn === true && (
          <div>
            <EditBatch
              rownumber={this.state.selectedRowNumber}
              clientCode={this.props.clientCode}
              onMappingUpdated={this.handleMappingUpdated}
            />
          </div>
        )}
        {this.state.EditExcelOn === true && (
          <div>
            <GenerateExcelFiles
              clientCode={this.props.clientCode}
              onMappingUpdated2={this.handleMappingUpdated2}
            />
          </div>
        )}
      </>
    );
  }
}

export default function ProgressSummary(props) {
  const { user } = useAuth();
  return (
    <ProgressSummaryx
      clientCode={user.thisClientcode}
      thisWidth={props.thisWidth}
      showPrior={props.showPrior}
      ShowCreateClientPresentation={props.ShowCreateClientPresentation}
    />
  );
}

const renderDescriptionCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (rowData.LINETYPE === "H") {
    style = {
      fontWeight: "bold",
      color: "white",
      backgroundColor: "black",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.LINETYPE === "X") {
    style = {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "#D9D9D9",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.LINETYPE === "T") {
    style = {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "#D9D9D9",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.LINETYPE === "B") {
    style = {};
  } else {
    style = {
      backgroundColor: "",
      borderLeft: "1px solid black",
    };
  }
  return <div style={style}>{rowData.DESCRIPTION}</div>;
};

const renderValueFieldCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (rowData.LINETYPE === "T") {
    style = {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "lightgrey",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  }
  if (rowData.LINETYPE === "X") {
    style = {
      color: "black",
      backgroundColor: "lightgrey",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
    };
  }
  if (rowData.LINETYPE === "") {
    style = {
      color: "black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  }
  if (rowData.LINETYPE === "H") {
    style = {
      backgroundColor: "#E6D180",
      borderRight: "1px solid black",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
    };
  }

  const isNegative = rowData.VALUEFIELD < 0;
  const absoluteValue = Math.abs(rowData.VALUEFIELD);
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(absoluteValue);

  if (rowData.LINETYPE === "H" || rowData.LINETYPE === "B") {
    return <div style={style}>&nbsp;</div>;
  } else {
    const displayValue = isNegative ? `(${formattedValue})` : formattedValue;
    return <div style={style}>{displayValue}&nbsp;&nbsp;</div>;
  }
};
