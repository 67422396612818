import React, { useState, useEffect, useCallback } from "react";
import { Form, Item, EmptyItem } from "devextreme-react/form";
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel";
//>>import "devextreme/dist/css/dx.light.css";
//>>import "./styles.css"; // Import the custom CSS file

const CFEnterStructure = (props) => {
  const [thisCustomer, setThisCustomer] = useState(props.clientCode); // selected customer
  const [formData, setFormData] = useState({}); // form data for the customer

  useEffect(() => {
    getClientIncomeStructure(thisCustomer) // call the function to fetch data
      .then((data) => {
        console.log("Data fetched: ", data);
        setFormData(data); // Assuming data is the object structure expected by the form
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
  }, [thisCustomer]);

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const updateClientIncomeStructureForm = () => {
    updateClientIncomeStructure(thisCustomer, formData) // Assuming thisCustomer is the selected customer code
      .then((response) => {
        console.log("Update successful:", response);
        props.onClose(); // Call the onClose function passed as a prop to close the form
      })
      .catch((error) => {
        console.error("There was an error updating the data:", error);
      });
  };

  const handleFieldDataChanged = useCallback(
    (e) => {
      setFormData((prevData) => ({
        ...prevData,
        [e.dataField]: e.value,
      }));
    },
    [setFormData]
  );

  const currencyEditorOptions = {
    format: {
      type: "currency",
      precision: 0,
    },
    alignment: "right",
    showClearButton: false,
  };

  return (
    <>
      <p> </p>
      <TabPanel>
        <TabItem title="Income Client One">
          <Form
            formData={formData}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation="top"
            minColWidth={300}
            colCount={6}
            onFieldDataChanged={handleFieldDataChanged}
            className="small-font"
          >
            <Item dataField="OWN1EMPLOYER" label={{ text: "Employer" }} />
            <EmptyItem colSpan={5} />
            <Item
              dataField="OWN1SALARYGROSS"
              label={{ text: "Gross Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1SALARYNET"
              label={{ text: "Net Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1COMMISSIONSGROSS"
              label={{ text: "Gross Commissions" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1COMMISSIONSNET"
              label={{ text: "Net Commissions" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1BENEFITSGROSS"
              label={{ text: "Gross Benefits" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1BENEFITSNET"
              label={{ text: "Net Benefits" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1BONUSGROSS"
              label={{ text: "Gross Bonus" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1BONUSNET"
              label={{ text: "Net Bonus" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1BONUSDESCRIPTION"
              label={{ text: "Bonus Description" }}
            />
            <Item
              dataField="OWN1BONUSFREQUENCY"
              label={{ text: "Bonus Frequency" }}
            />
            <EmptyItem colSpan={2} />
            <Item
              dataField="OWN1RSUGROSS"
              label={{ text: "Gross RSU" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1RSUNET"
              label={{ text: "Net RSU" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1CARALLOWANCEGROSS"
              label={{ text: "Gross Car Allowance" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1CARALLOWANCENET"
              label={{ text: "Net Car Allowance" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1RPPDEDUCTION"
              label={{ text: "RPP Deduction" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1UNIONDUES"
              label={{ text: "Union Dues" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1GROUPINVESTINGREGISTERED"
              label={{ text: "Group Investing Registered" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1GROUPINVESTINGREGISTEREDPERCENTOFSALARY"
              label={{ text: "Group Investing Registered % of Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1GROUPINVESTINGNONREGISTERED"
              label={{ text: "Group Investing Non-Registered" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1GROUPINVESTINGNONREGISTEREDPERCENTOFSALARY"
              label={{ text: "Group Investing Non-Registered % of Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1GROUPINVESTINGREGISTEREDESPP"
              label={{ text: "Group Investing Registered ESPP" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1GROUPINVESTINGESPPPERCENTOFSALARY"
              label={{ text: "Group Investing ESPP % of Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
          </Form>
        </TabItem>
        <TabItem title="Income Client Two">
          <Form
            formData={formData}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation="top"
            minColWidth={300}
            colCount={6}
            onFieldDataChanged={handleFieldDataChanged}
            className="small-font"
          >
            <Item dataField="OWN2EMPLOYER" label={{ text: "Employer" }} />
            <EmptyItem colSpan={5} />
            <Item
              dataField="OWN2SALARYGROSS"
              label={{ text: "Gross Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2SALARYNET"
              label={{ text: "Net Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2COMMISSIONSGROSS"
              label={{ text: "Gross Commissions" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2COMMISSIONSNET"
              label={{ text: "Net Commissions" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2BENEFITSGROSS"
              label={{ text: "Gross Benefits" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2BENEFITSNET"
              label={{ text: "Net Benefits" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2BONUSGROSS"
              label={{ text: "Gross Bonus" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2BONUSNET"
              label={{ text: "Net Bonus" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2BONUSDESCRIPTION"
              label={{ text: "Bonus Description" }}
            />
            <Item
              dataField="OWN2BONUSFREQUENCY"
              label={{ text: "Bonus Frequency" }}
            />
            <EmptyItem colSpan={2} />
            <Item
              dataField="OWN2RSUGROSS"
              label={{ text: "Gross RSU" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2RSUNET"
              label={{ text: "Net RSU" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2CARALLOWANCEGROSS"
              label={{ text: "Gross Car Allowance" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2CARALLOWANCENET"
              label={{ text: "Net Car Allowance" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2RPPDEDUCTION"
              label={{ text: "RPP Deduction" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2UNIONDUES"
              label={{ text: "Union Dues" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2GROUPINVESTINGREGISTERED"
              label={{ text: "Group Investing Registered" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2GROUPINVESTINGREGISTEREDPERCENTOFSALARY"
              label={{ text: "Group Investing Registered % of Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2GROUPINVESTINGNONREGISTERED"
              label={{ text: "Group Investing Non-Registered" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2GROUPINVESTINGNONREGISTEREDPERCENTOFSALARY"
              label={{ text: "Group Investing Non-Registered % of Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2GROUPINVESTINGREGISTEREDESPP"
              label={{ text: "Group Investing Registered ESPP" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2GROUPINVESTINGESPPPERCENTOFSALARY"
              label={{ text: "Group Investing ESPP % of Salary" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
          </Form>
        </TabItem>

        <TabItem title="Other Income">
          <Form
            formData={formData}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation="top"
            minColWidth={300}
            colCount={5}
            onFieldDataChanged={handleFieldDataChanged}
            className="small-font"
          >
            <Item
              dataField="EIINCOME"
              label={{ text: "EI Income" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="EIENDDATE"
              label={{ text: "EI End Date" }}
              editorType="dxDateBox"
            />
            <Item
              dataField="EITAXATSOURCE"
              label={{ text: "EI Tax At Source" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <EmptyItem colSpan={2} />
            <Item
              dataField="PENSION"
              label={{ text: "Pension" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="CPP"
              label={{ text: "CPP" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OAS"
              label={{ text: "OAS" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <EmptyItem colSpan={2} />
            <Item
              dataField="DIVIDENDS"
              label={{ text: "Dividends" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="INVESTMENTINCOME"
              label={{ text: "Investment Income" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="CAPITALGAINS"
              label={{ text: "Capital Gains" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <EmptyItem colSpan={2} />
            <Item
              dataField="RENTALINCOME"
              label={{ text: "Rental Income" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="RENTALOWNERSHIP"
              label={{ text: "Rental Ownership" }}
            />
            <Item
              dataField="RENTALCASHFLOW"
              label={{ text: "Rental Cash Flow" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <EmptyItem colSpan={2} />
            <Item
              dataField="SOLEPROP"
              label={{ text: "Sole Prop" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="SOLEPROPOWNERSHIP"
              label={{ text: "Sole Prop Ownership" }}
            />
            <Item
              dataField="SOLEPROPREVENUE"
              label={{ text: "Sole Prop Revenue" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="SOLEPROPEXPENSES"
              label={{ text: "Sole Prop Expenses" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="SOLEPROPHST"
              label={{ text: "Sole Prop HST" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="SOLEPROPHSTTYPE"
              label={{ text: "Sole Prop HST Type" }}
            />
            <Item
              dataField="SOLEPROPHSTINSTALLEMENTS"
              label={{ text: "Sole Prop HST Installments" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="SOLEPROPTAXOWING"
              label={{ text: "Sole Prop Tax Owing" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="SOLEPROPTAXINSTALLMENTS"
              label={{ text: "Sole Prop Tax Installments" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
          </Form>
        </TabItem>
        <TabItem title="Expenses">
          <Form
            formData={formData}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation="top"
            minColWidth={300}
            colCount={4}
            onFieldDataChanged={handleFieldDataChanged}
            className="small-font"
          >
            <Item
              dataField="LIVINGEXPENSES"
              label={{ text: "Living Expenses" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="CHILDCARENAME"
              label={{ text: "Childcare Name" }}
            />
            <Item
              dataField="CHILDCAREDOB"
              label={{ text: "Child Care DOB" }}
              editorType="dxDateBox"
            />
            <Item
              dataField="CHILDCAREAMOUNT"
              label={{ text: "Child Care Amount" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="CARPAYMENTS"
              label={{ text: "Car Payments" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item dataField="CAROWNERSHIP" label={{ text: "Car Ownership" }} />
            <Item dataField="CARDETAILS" label={{ text: "Car Details" }} />
            <Item
              dataField="CARPAYMENT"
              label={{ text: "Car Payment" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="CARPAYMENTRATE"
              label={{ text: "Car Payment Rate" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="TFSACONTRIBUTIONS"
              label={{ text: "TFSA Contributions" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="CARRYINGCHARGESINVESTMENTLOANINTEREST"
              label={{ text: "Investment Loan Interest" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="BEACHSTREETFEES"
              label={{ text: "Beach Street Fees" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="DEDUCTIBLEINTEREST"
              label={{ text: "Deductible Interest" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="EQUIGENESISPROGRAM"
              label={{ text: "Equi Genesis Program" }}
            />
            <Item
              dataField="EQUIGENESISDEDUCTION"
              label={{ text: "Equi Genesis Deduction" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1TAXDEDUCTIONS"
              label={{ text: "Tax Deductions" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1PREMIERFEES"
              label={{ text: "Premier Fees" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1MOVINGEXPENSES"
              label={{ text: "Moving Expenses" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1DISABILITYCREDIT"
              label={{ text: "Disability Credit" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2TAXDEDUCTIONS"
              label={{ text: "Tax Deductions" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2PREMIERFEES"
              label={{ text: "Premier Fees" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2MOVINGEXPENSES"
              label={{ text: "Moving Expenses" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2DISABILITYCREDIT"
              label={{ text: "Disability Credit" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
          </Form>
        </TabItem>
        <TabItem title="Carry Forwards">
          <Form
            formData={formData}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation="top"
            minColWidth={300}
            colCount={4}
            onFieldDataChanged={handleFieldDataChanged}
            className="small-font"
          >
            <Item
              dataField="OWN1RRSPLIMIT"
              label={{ text: "Client 1 RRSP Limit" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1CONTRIBUTIONSTODATE"
              label={{ text: "Client 1 Contributions To Date" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1TFSALIMIT"
              label={{ text: "Client 1 TFSA Limit" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1TFSACONTRIBUTIONSTODATE"
              label={{ text: "Client 1 TFSA Contributions To Date" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN1FOREIGNINCOMEDECLARATIONREQUIRED"
              label={{ text: "Client 1 Foreign Income Declaration Required" }}
              editorType="dxCheckBox"
            />
            <EmptyItem />
            <EmptyItem />
            <EmptyItem />
            <Item
              dataField="OWN2RRSPLIMIT"
              label={{ text: "Client 2 RRSP Limit" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2CONTRIBUTIONSTODATE"
              label={{ text: "Client 2 Contributions To Date" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2TFSALIMIT"
              label={{ text: "Client 2 TFSA Limit" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2TFSACONTRIBUTIONSTODATE"
              label={{ text: "Client 2 TFSA Contributions To Date" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="OWN2FOREIGNINCOMEDECLARATIONREQUIRED"
              label={{ text: "Client 2 Foreign Income Declaration Required" }}
              editorType="dxCheckBox"
            />
          </Form>
        </TabItem>
        <TabItem title="Other">
          <Form
            formData={formData}
            readOnly={false}
            showColonAfterLabel={true}
            labelLocation="top"
            minColWidth={300}
            colCount={4}
            onFieldDataChanged={handleFieldDataChanged}
            className="small-font"
          >
            <EmptyItem />
            <EmptyItem />
            <EmptyItem />
            <EmptyItem />

            <Item dataField="ONECUSTOMDESCRIPTION" label={{ text: "One" }} />
            <Item
              dataField="ONECUSTOMAMOUNT"
              label={{ text: "Amount" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="ONECUSTOMROW"
              label={{ text: "Row" }}
              editorType="dxNumberBox"
            />
            <EmptyItem />
            <Item dataField="TWOCUSTOMDESCRIPTION" label={{ text: "Two" }} />
            <Item
              dataField="TWOCUSTOMAMOUNT"
              label={{ text: "Amount" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="TWOCUSTOMROW"
              label={{ text: "Row" }}
              editorType="dxNumberBox"
            />
            <EmptyItem />
            <Item
              dataField="THREECUSTOMDESCRIPTION"
              label={{ text: "Three" }}
            />
            <Item
              dataField="THREECUSTOMAMOUNT"
              label={{ text: "Amount" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="THREECUSTOMROW"
              label={{ text: "Row" }}
              editorType="dxNumberBox"
            />
            <EmptyItem />
            <Item dataField="FOURCUSTOMDESCRIPTION" label={{ text: "Four" }} />
            <Item
              dataField="FOURCUSTOMAMOUNT"
              label={{ text: "Amount" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="FOURCUSTOMROW"
              label={{ text: "Row" }}
              editorType="dxNumberBox"
            />
            <EmptyItem />
            <Item dataField="FIVECUSTOMDESCRIPTION" label={{ text: "Five" }} />
            <Item
              dataField="FIVECUSTOMAMOUNT"
              label={{ text: "Amount" }}
              editorType="dxNumberBox"
              editorOptions={currencyEditorOptions}
            />
            <Item
              dataField="FIVECUSTOMROW"
              label={{ text: "Row" }}
              editorType="dxNumberBox"
            />
          </Form>
        </TabItem>
      </TabPanel>
      <div style={{ marginTop: 20 }}>
        <button
          type="button"
          className="dx-button dx-button-success"
          onClick={updateClientIncomeStructureForm}
        >
          Update
        </button>
      </div>
    </>
  );
};

export default CFEnterStructure;

const getClientIncomeStructure = async (myClient) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getClientIncomeStructure`;
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("types list", json.user_response.bankq);
  return json.user_response.bankq;
};

const updateClientIncomeStructure = async (myClient, formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
      bankq: formData,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/updateClientIncomeStructure`;
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    return {
      data: [],
      error: "System did not respond",
    };
  }
  const json = await response.json();
  console.log("types list", json.user_response.bankq);
  return json.user_response.bankq;
};
