import defaultUser from "../utils/default-user";
import { login, login2 } from "./MyOwnServices";

const currentUser = {};
var thisOK = "0";
export async function signIn(email, password) {
  try {
    // Send request
    //console.log(email, password);
    await login(email, password).then((response) => {
      thisOK = response.returnOK;
      //console.log("response", response);
      currentUser.name = response.clientname;
      currentUser.clientCode = response.clientcode;
      currentUser.administrator = response.administrator;
      currentUser.avatarUrl =
        "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/07.png";
      currentUser.guid = response.guid;
      currentUser.widget = response.widget;
      currentUser.UserCode = response.UserCode;
      currentUser.UserPassword = response.UserPassword;
      //currentUser.FlinksEnvironmentRetail=response.FlinksEnvironmentRetail
      currentUser.FlinkCustomerRetail = response.FlinkCustomerRetail;
      currentUser.FlinksAPIDomainRetail = response.FlinksAPIDomainRetail;
      currentUser.FlinksConnectDomainRetail =
        response.FlinksConnectDomainRetail;
      currentUser.FlinksProjectIDRetail = response.FlinksProjectIDRetail;
      currentUser.FlinkCustomerWealth = response.FlinkCustomerWealth;
      currentUser.FlinksAPIDomainWealth = response.FlinksAPIDomainWealth;
      currentUser.FlinksConnectDomainWealth =
        response.FlinksConnectDomainWealth;
      currentUser.FlinksProjectIDWealth = response.FlinksProjectIDWealth;
      currentUser.thisClientcode = response.thisClientcode;
      currentUser.lastClientUpdated = response.lastClientUpdated;
      currentUser.DateFormat = response.DateFormat;

      // console.log(
      //   "this:",
      //   currentUser.thisClientcode,
      //   "sent",
      //   currentUser.clientCode,
      //   "client",
      //   currentUser.lastClientUpdated
      // );
    });
    if (thisOK === "1") {
      return {
        isOk: true,
        data: currentUser,
      };
    } else {
      return { isOk: false };
    }
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function signInOriginal(email, password) {
  try {
    // Send request
    //console.log(email, password);

    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

export async function getUser() {
  try {
    // Simulate fetching user data or use actual logic to get user details
    const defaultUser = { name: "John Doe", email: "johndoe@example.com" }; // Example user data
    return {
      isOk: true,
      data: defaultUser, // Assuming you will return some user data
    };
  } catch (error) {
    return {
      isOk: false,
      message: `Failed to get user: ${error.message}`, // More detailed error message
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Simulate an API call to create an account
    // You would replace this with your actual API request logic, e.g.:
    // const response = await api.createUser(email, password);
    // return response;

    console.log("Creating account for:", email);

    // Example of a successful response
    return {
      isOk: true,
      message: "Account successfully created!",
      // Include additional data if necessary, e.g., user details or token:
      // data: response.data
    };
  } catch (error) {
    // Capture and return the error message for more detailed feedback
    return {
      isOk: false,
      message: `Failed to create account: ${error.message}`,
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Simulate an API call to change the password
    // You would replace this with your actual API request logic, e.g.:
    // const response = await api.changePassword(email, recoveryCode);
    // return response;

    console.log("Changing password for:", email);

    // Example of a successful response
    return {
      isOk: true,
      message: "Password successfully changed!",
    };
  } catch (error) {
    // Capture and return the error message for more detailed feedback
    return {
      isOk: false,
      message: `Failed to change password: ${error.message}`,
    };
  }
}

export async function resetPassword(email) {
  try {
    // Simulate an API call to reset the password
    // Replace this with actual API request logic, e.g.:
    // const response = await api.resetPassword(email);
    // return response;

    console.log("Resetting password for:", email);

    // Example of a successful response
    return {
      isOk: true,
      message: "Password reset email has been sent!",
    };
  } catch (error) {
    // Capture and return the error message for more detailed feedback
    return {
      isOk: false,
      message: `Failed to reset password: ${error.message}`,
    };
  }
}

export async function signIn2(user, password) {
  try {
    // Send request
    //console.log("inside signin2: ", user, password);
    await login2(user.email, password).then((response) => {
      thisOK = response.returnOK;
      //console.log("response", response);
      currentUser.name = response.clientname;
      currentUser.clientCode = response.clientcode;
      currentUser.administrator = response.administrator;
      currentUser.avatarUrl =
        "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/07.png";
      currentUser.guid = response.guid;
      currentUser.widget = response.widget;
      currentUser.UserCode = response.UserCode;
      currentUser.UserPassword = response.UserPassword;
      //currentUser.FlinksEnvironmentRetail=response.FlinksEnvironmentRetail
      currentUser.FlinkCustomerRetail = response.FlinkCustomerRetail;
      currentUser.FlinksAPIDomainRetail = response.FlinksAPIDomainRetail;
      currentUser.FlinksConnectDomainRetail =
        response.FlinksConnectDomainRetail;
      currentUser.FlinksProjectIDRetail = response.FlinksProjectIDRetail;
      currentUser.FlinkCustomerWealth = response.FlinkCustomerWealth;
      currentUser.FlinksAPIDomainWealth = response.FlinksAPIDomainWealth;
      currentUser.FlinksConnectDomainWealth =
        response.FlinksConnectDomainWealth;
      currentUser.FlinksProjectIDWealth = response.FlinksProjectIDWealth;
      currentUser.thisClientcode = response.thisClientcode;
      currentUser.lastClientUpdated = response.lastClientUpdated;
      currentUser.DateFormat = response.DateFormat;

      // console.log(
      //   "admin:",
      //   currentUser.administrator,
      //   "this:",
      //   currentUser.thisClientcode,
      //   "sent",
      //   currentUser.clientCode,
      //   "client",
      //   currentUser.lastClientUpdated
      // );
    });
    if (thisOK === "1") {
      return {
        isOk: true,
        data: currentUser,
      };
    } else {
      return { isOk: false };
    }
  } catch {
    return {
      isOk: false,
      message: "Authentication failed",
    };
  }
}

// export async function signIn(email, password) {
//   try {
//     // Send request
//     console.log(email, password);
//     login(email, password).then((response) => {
//       currentUser.email = response.clientname;
//       currentUser.companynumber = response.companynumber;
//       currentUser.avatarUrlavatarUrl =
//         "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png";
//     });
//     return {
//       isOk: true,
//       data: currentUser,
//     };
//   } catch {
//     return {
//       isOk: false,
//       message: "Authentication failed",
//     };
//   }
// }
