import React, { useState, useEffect } from "react";
import axios from "axios";
//>>import "./profile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button } from "devextreme-react/button";
import { fetchcompany } from "../../api/MyOwnServices";
import SelectBox from "devextreme-react/select-box";

import { getClients } from "./../clientManagement/clientManagementData";
import { getFirstAndLastCustomers } from "./extractBankListData";

const ExtractBankList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [myClientCode, setClientCode] = useState(props.clientCode);
  const MySwal = withReactContent(Swal);
  const [filename, setFilename] = useState(""); // State to store the generated filename
  const [fromclient, setFromClient] = useState("");
  const [toclient, setToClient] = useState("");
  const [ServerUsername, setServerUsername] = useState("");
  const [ServerPassword, setServerPassword] = useState("");
  const [ServerName, setServerName] = useState("");
  const [ServerDatabase, setServerDatabase] = useState("");
  const companynumbersent = "0001";
  const [customerList, setCustomerList] = useState([]); // this is the array of customers

  useEffect(() => {
    const fetchInitialData = async () => {
      // Fetch company data first
      const companyResult = await fetchcompany(companynumbersent);
      setServerUsername(companyResult.ServerUsername);
      setServerPassword(companyResult.ServerPassword);
      setServerName(companyResult.ServerName);
      setServerDatabase(companyResult.ServerDatabase);

      console.log("company: ", companyResult);

      // Fetch customer data next
      const customerResult = await getClients();
      console.log("Fetched customer data:", customerResult.data);
      if (customerResult && customerResult.data) {
        const filteredCustomers = customerResult.data.filter(
          (customer) => !customer.inactive
        );
        setCustomerList(filteredCustomers);
      }

      // Fetch first and last customers last
      const customerRangeResult = await getFirstAndLastCustomers();
      console.log("Fetched first and last customers:", customerRangeResult);
      if (customerRangeResult) {
        setFromClient(customerRangeResult.fromClientCode);
        setToClient(customerRangeResult.toClientCode);
        console.log(
          "initial value",
          customerRangeResult.fromClientCode,
          "from client:",
          fromclient
        );
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    console.log("from client:", fromclient, "to client:", toclient);
  }, [fromclient, toclient]);

  const setFromClientEntry = (e) => {
    if (!e.value) return;
    setFromClient(e.value);
  };

  const setToClientEntry = (e) => {
    if (!e.value) return;
    setToClient(e.value);
  };

  const ProcessBankTransactions = async () => {
    try {
      setIsLoading(true);

      // SQL Connection Information
      const dbConfig = {
        user: ServerUsername,
        password: ServerPassword,
        server: ServerName,
        database: ServerDatabase,
        options: {
          encrypt: true, // Use encryption
          trustServerCertificate: true, // Allow self-signed certificate
        },
      };
      console.log("config is ", dbConfig);

      // SQL Query String
      const sqlQuery = `select d.bankname as bank ,e.name as name ,f.accountdescription,f.bankaccounttype,
      sum(a.TransactionAmount) as BankTotal,e.inactive from FPClientBankTransaction as a
         join FPTransactionTypes as b on b.FPTransactionCode=a.FPTransactionCode 
         join FPTransactionGroups as c on c.Code=b.TransactionGroup 
         join fpbanks as d on d.bankcode=a.bankcode
         join fpclientmasterfile as e on e.clientcode=a.clientcode
         join fpclientbankaccount as f on f.uniqueid =a.bankaccountid
         where a.ClientCode>='${fromclient}' and  a.ClientCode<='${toclient}' and b.InterestCharge=0 and a.BANKACCOUNTNUMBER<>''
         group by a.clientcode,d.bankname,e.name ,a.bankaccountnumber,f.accountdescription,f.bankaccounttype,e.inactive`;

      // Call the server to execute the SQL query and generate the file
      const url = `${process.env.REACT_APP_BASE_URL_AXIOS}/GenerateExcelFile`;
      const response = await axios.post(url, {
        dbConfig,
        sqlQuery,
      });

      setFilename(response.data.filename); // Store the filename returned by the server
      console.log("response was", response.data.filename);

      MySwal.fire({
        icon: "success",
        title: "Completed",
        text: "Bank Transactions charges have been successfully generated ",
      });
    } catch (error) {
      console.error("Error processing transactions:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue processing the transactions.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadFile = () => {
    if (filename) {
      // Use environment variable or default to localhost for development
      const url = `${
        process.env.REACT_APP_BASE_URL_AXIOS || "http://localhost:4000"
      }/download/${filename}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setFilename(""); // Clear the filename after download
    }
  };

  return (
    <>
      {/* <p>
        values returned: from client: {fromclient} To Client: {toclient}
      </p> */}
      <p style={{ marginRight: "10px", marginBottom: "0" }}>From Client:</p>
      <SelectBox
        className="white-text-selectbox"
        style={{ width: "200px", height: "40px", marginRight: "10px" }}
        items={customerList}
        valueExpr="label"
        displayExpr="label"
        value={fromclient}
        searchEnabled={true}
        onValueChanged={setFromClientEntry}
      />
      <p style={{ marginRight: "10px", marginBottom: "0" }}>To Client:</p>
      <SelectBox
        className="white-text-selectbox"
        style={{ width: "200px", height: "40px", marginRight: "10px" }}
        items={customerList}
        valueExpr="label"
        displayExpr="label"
        value={toclient}
        searchEnabled={true}
        onValueChanged={setToClientEntry}
      />

      <div className="red-color responsive-paddingsx">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <Button
            text="Generate Bank Transactions"
            onClick={ProcessBankTransactions}
            style={{
              width: "350px",
              height: "30px",
              marginTop: "30px",
            }}
          ></Button>
        </div>
      </div>
      {filename && (
        <div className="red-color responsive-paddingsx">
          <h3>File Download is ready</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <Button
              text="Download Bank Transactions Excel File"
              onClick={downloadFile}
              style={{ width: "350px", height: "30px", marginTop: "30px" }}
            ></Button>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="spinner-container" style={{ marginTop: "50px" }}>
          <p style={{ marginBottom: "10px" }}>
            Processing please wait &nbsp;&nbsp;
          </p>
          <FontAwesomeIcon icon={faSpinner} spin className="large-spinner" />
        </div>
      )}
    </>
  );
};

export default ExtractBankList;

//https://api.beachstreetwealth.com/download/ExcelFile_1730297074020.xlsx
//https://api.beachstreetwealth.com:4000/download/ExcelFile_1730296511668.xlsx
