import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  FilterRow,
  HeaderFilter,
} from "devextreme-react/data-grid";

import { mystore12 } from "./clientInvestmentsData";
import "devextreme-react/text-area";
import "devextreme/data/data_source";

function ClientInvestmentsSummary(props) {
  const [localRefreshKey, setLocalRefreshKey] = useState(0); // Local refresh key for DataGrid updates
  const [thisStockID, setThisStockID] = useState(props.StockID);

  const onInitNewRow = (e) => {
    e.data.INVESTMENTNAME = props.StockName;
    e.data.CLIENTCODE = props.ClientCode;
  };

  const handleRowUpdated = (e) => {
    updateCurrentValueByNewestDate(thisStockID);

    // Notify parent about the updated value if needed
    // if (props.onValueUpdate) {
    //   props.onValueUpdate(updatedValue);
    // }
    if (props.onRefresh) {
      props.onRefresh();
    }

    // Increment local refresh key to trigger grid update without affecting parent
    setLocalRefreshKey((prevKey) => prevKey + 1);
  };

  const handleRowInsertedOrRemoved = () => {
    updateCurrentValueByNewestDate(thisStockID);
    // Trigger parent refresh only if necessary
    if (props.onRefresh) {
      props.onRefresh();
    }

    // Locally refresh the grid without affecting parent component
    setLocalRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    // Sync local refresh key with parent-provided refresh key if required
    setLocalRefreshKey(props.refreshKey);
  }, [props.refreshKey]);

  const updateCurrentValueByNewestDate = async (thisStockID) => {
    try {
      // Fetch all transactions for this UNIQUEID
      const transactions = await mystore12(thisStockID); // Assuming mystore12 fetches transactions

      // Find the transaction with the newest date
      const newestDateTransaction = transactions.sort(
        (a, b) => new Date(b.TRANSACTIONDATE) - new Date(a.TRANSACTIONDATE)
      )[0];

      if (newestDateTransaction) {
        const latestValue = newestDateTransaction.CURRENTVALUE;
      }
    } catch (error) {
      console.error("Error updating current value by newest date:", error);
    }
  };

  return (
    <div className="red-color">
      <p>Stock Summary By Period</p>
      <DataGrid
        dataSource={mystore12(props.StockID)}
        key={localRefreshKey} // Use local refresh key to avoid triggering parent component re-renders
        showBorders={true}
        remoteOperations={false}
        width={"100%"}
        columnAutoWidth={true}
        paging={{ pageSize: 4 }}
        onInitNewRow={onInitNewRow}
        onRowUpdated={handleRowUpdated}
        onRowRemoved={handleRowInsertedOrRemoved}
        onRowInserted={handleRowInsertedOrRemoved}
        style={{ border: "1px solid black" }}
      >
        <Editing
          mode="cell"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
        ></Editing>
        <Column
          dataField="CLIENTCODE"
          width={100}
          caption={"Client Code"}
          hidingPriority={8}
          allowEditing={false}
          visible={true}
        />
        <Column
          dataField={"INVESTMENTNAME"}
          width={150}
          caption={"Account #"}
          hidingPriority={8}
          allowEditing={false}
          visible={true}
        />
        <Column
          dataType="date"
          dataField={"TRANSACTIONDATE"}
          width={150}
          caption={"Date (mm/dd/yyyy)"}
          hidingPriority={8}
          visible={true}
        />
        <Column
          dataField={"CURRENTVALUE"}
          width={150}
          caption={"Value"}
          hidingPriority={8}
          visible={true}
          format={"$###,###,###.00"}
        />
      </DataGrid>
    </div>
  );
}

export default ClientInvestmentsSummary;
