import React, { useEffect, useState } from "react";
import DataGrid, {
  Column,
  Sorting,
  Editing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
} from "devextreme-react/data-grid";
import { mystore, insertRows } from "./netWorthData";
import { useAuth } from "../../contexts/auth";

class NetWorthx extends React.Component {
  constructor(props) {
    super(props);
    this.applyFilterTypes = [
      {
        key: "auto",
        name: "Immediately",
      },
      {
        key: "onClick",
        name: "On Button Click",
      },
    ];
    this.state = {
      currentRow: 0,
      filterValue: "90",
      selectedRowKeys: [],
      showFilterRow: true,
      showHeaderFilter: true,
      companyCode: 1,
      currentFilter: this.applyFilterTypes[0].key,
      isLoading: true,
      numberOfLines: 0, // State for the number of lines to add
      startLine: 0, // State for the line to start at
      gridKey: 0, // Key to force a re-render of the DataGrid
    };
  }

  handleSelectionChanged(e) {
    this.setState({ selectedRowKeys: e.selectedRowKeys });
    if (e.selectedRowKeys.length > 0) {
      this.setState({ currentRow: e.selectedRowKeys[0] });
    }
  }

  handleEditingStart(e) {
    console.log("Editing is starting for row", e.data);
    const rowToBeEdited = e.data;
    if (rowToBeEdited.someField === "someValue") {
      e.cancel = true;
    }
  }

  handleProceed = async () => {
    const { numberOfLines, startLine } = this.state;

    if (numberOfLines > 0 && startLine >= 0) {
      try {
        // Set loading state
        this.setState({ isLoading: true });

        // Await the completion of the `insertRows` function
        await insertRows(numberOfLines, startLine);

        console.log(
          `Successfully added ${numberOfLines} lines starting at line ${startLine}.`
        );

        // Force a re-render by updating the key
        this.setState((prevState) => ({
          gridKey: prevState.gridKey + 1, // Increment the key
          isLoading: false,
        }));
      } catch (error) {
        console.error("Error adding rows:", error);
        alert("Failed to add rows. Please try again.");
        this.setState({ isLoading: false });
      }
    } else {
      alert("Please enter valid values for both fields.");
    }
  };

  render() {
    return (
      <div className="content-block dx-card responsive-paddings">
        {/* Input fields and Proceed button */}

        <div style={{ marginBottom: "20px" }}>
          number of lines to Add&nbsp;&nbsp;
          <input
            type="number"
            placeholder="Number of lines to add"
            value={this.state.numberOfLines}
            onChange={(e) =>
              this.setState({
                numberOfLines: parseInt(e.target.value, 10) || 0,
              })
            }
            style={{ marginRight: "10px" }}
          />
          Row to start after&nbsp;&nbsp;
          <input
            type="number"
            placeholder="Start line"
            value={this.state.startLine}
            onChange={(e) =>
              this.setState({ startLine: parseInt(e.target.value, 10) || 0 })
            }
            style={{ marginRight: "10px" }}
          />
          <button onClick={this.handleProceed}>Proceed</button>
        </div>
        <DataGrid
          dataSource={mystore(this.state.companyCode)}
          keyExpr="UNIQUEID"
          showBorders={true}
          remoteOperations={false}
          onSelectionChanged={this.handleSelectionChanged.bind(this)}
          onEditingStart={this.handleEditingStart}
          width={"80%"}
          key={this.state.gridKey}
        >
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Editing
            mode="cell"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
          />
          <Column dataField="ROWNUMBER" caption="Row" width={150} />
          <Column dataField="ROWDESCRIPTION" caption="Description" />
          <Column
            dataField={"NETWORTHCODE"}
            width={190}
            caption={"Net Worth Code"}
            hidingPriority={8}
            visible={true}
          />
          <Column
            dataField={"FORMULA2"}
            width={190}
            caption={"Formula"}
            hidingPriority={8}
            visible={true}
          />
          <Column
            dataField={"LINETYPE"}
            width={190}
            caption={"Type"}
            hidingPriority={8}
            visible={true}
          />
          <Column
            dataField={"UNIQUEID"}
            width={90}
            hidingPriority={8}
            dataType="Number"
            visible={false}
            allowEditing={false}
          />
        </DataGrid>
      </div>
    );
  }
}

export default function NetWorth() {
  const { user } = useAuth();
  return <NetWorthx companyCode={user.companyCode} />;
}
