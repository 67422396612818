import React, { useState, useEffect, useCallback } from "react";
import DataGrid, {
  Column,
  HeaderFilter,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { Button } from "devextreme-react";
import { useAuth } from "../../../contexts/auth";
import TextBox from "devextreme-react/text-box";

import {
  getTransactionTypes,
  getBanks,
  fetchThisClientData,
} from "./segmentData";
import { getSummary } from "./segmentData2";

let pageoption = 90;

function ClientTransactionsx(props) {
  const [transTypes, setTransTypes] = useState(null);
  const [bankAccounts, setBankAccounts] = useState(null);
  const [lastBankAccountNumber, setLastBankAccountNumber] = useState("");
  const [lastSegmentNumbmer, setLastSegmentNumber] = useState("");
  const [clientCode, setClientCode] = useState(props.clientCode);
  const [currentBankAccount, setCurrentBankAccount] = useState(null);
  const [segmentNumber, setSegmentNumber] = useState(0);
  const [filterValue, setFilterValue] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filterOption, setFilterOption] = useState(90);
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const applyFilterTypes = [
    { key: "auto", name: "Immediately" },
    { key: "onClick", name: "On Button Click" },
  ];
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0]);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);

  const handleStartDateChange = (e) => {
    setStartdate(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleEndDateChange = (e) => {
    setEnddate(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (props.clientCode && enddate && currentBankAccount) {
      const formattedEndDate = new Date(enddate)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "2-digit",
        })
        .toLowerCase()
        .replace(/\s/g, "-");

      console.log(
        "Fetching summary data...",
        props.clientCode,
        enddate,
        currentBankAccount,
        segmentNumber
      );

      getSummary({
        myClient: props.clientCode,
        enddate: formattedEndDate,
        currentBankAccount: currentBankAccount,
        segment: segmentNumber,
      })
        .then((data) => {
          console.log("Data fetched:", data.data);
          if (Array.isArray(data.data)) {
            setSummaryData(data.data);
          } else {
            console.error("Expected data to be an array:", data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching summary data:", error);
        });
    }
  }, [props.clientCode, enddate, currentBankAccount, segmentNumber]);

  useEffect(() => {
    console.log("Summary data updated:", summaryData);
    setRefreshKey((prevKey) => prevKey + 1);
  }, [summaryData]);

  //console.log("Rendering DataGrid with summaryData:", summaryData);

  useEffect(() => {
    fetchThisClientData(props.clientCode)
      .then((data) => {
        //console.log("Client data fetched:", data);
        setStartdate(data.STARTDATE);
        setEnddate(data.ENDDATE);
        setBankAccounts(data.data);
      })
      .catch((error) => {
        console.error("Error fetching client data:", error);
      });

    getTransactionTypes()
      .then((data) => {
        //console.log("Transaction types fetched:", data);
        setTransTypes(data.data);
      })
      .catch((error) => {
        console.error("Error fetching transaction types:", error);
      });

    getBanks(props.clientCode)
      .then((data) => {
        //console.log("Banks fetched:", data);
        setBankAccounts(data.data);
      })
      .catch((error) => {
        console.error("Error fetching banks:", error);
      });
  }, [props.clientCode]);

  const setAccountData = (e) => {
    setRefreshKey((prevKey) => prevKey + 1);
    setCurrentBankAccount(e.value);
  };

  const clearBankAccount = () => {
    setCurrentBankAccount("");
  };

  const handleSegmentChange = (e) => {
    setSegmentNumber(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const renderBoldCell = ({ data, displayValue }) => (
    <span
      style={{
        fontWeight:
          data.TRANSACTIONTYPEDESCRIPTION === "Total" ? "bold" : "normal",
      }}
    >
      {displayValue}
    </span>
  );

  return (
    <>
      <div className="red-color responsive-paddingsx">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <span>Summary</span> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div>
              <label>Bank Account:</label>
              <SelectBox
                style={{ width: "250px", height: "30px" }}
                items={bankAccounts}
                valueExpr="BANKACCOUNTNUMBER"
                displayExpr={(item) =>
                  item
                    ? `${item.BANKNAME} - ${item.BANKACCOUNTNUMBER} - ${item.ACCOUNTDESCRIPTION}`
                    : ""
                }
                value={currentBankAccount}
                searchEnabled={true}
                onValueChanged={setAccountData}
              />
            </div>
            <label>
              Segment:
              <TextBox
                value={segmentNumber}
                onValueChanged={handleSegmentChange}
                className="white-text-selectbox"
                style={{ width: "50px", height: "30px", marginTop: "5px" }}
              />
            </label>
            <div>
              <label>
                End Date (MM/DD/YYYY):
                <DateBox
                  type="date"
                  value={enddate}
                  onValueChanged={handleEndDateChange}
                />
              </label>
            </div>
          </div>
        </div>
        <Button text="Clear Account Selection" onClick={clearBankAccount} />

        <p> </p>
        <div className="custom-container">
          <DataGrid
            dataSource={summaryData}
            key={refreshKey} // This key will force a refresh when it changes
            columnAutoWidth={true}
            width={"50%"}
            remoteOperations={false}
            paging={false}
          >
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            <SearchPanel visible={false} width={240} placeholder="Search..." />

            <Column
              allowFiltering={true}
              dataField={"BANKACCOUNTNUMBER"}
              caption={"Bank Account Number"}
              hidingPriority={7}
              allowEditing={true}
              visible={false}
            />
            <Column
              dataField="SEGMENTNUMBER"
              caption="Segment"
              calculateCellValue={(rowData) =>
                rowData.TRANSACTIONTYPEDESCRIPTION === "Total"
                  ? ""
                  : rowData.SEGMENTNUMBER
              }
            />

            <Column
              dataField="FPTRANSACTIONCODE"
              caption="Type"
              calculateCellValue={(rowData) =>
                rowData.TRANSACTIONTYPEDESCRIPTION === "Total"
                  ? ""
                  : rowData.FPTRANSACTIONCODE
              }
            />

            <Column
              dataField="TRANSACTIONTYPEDESCRIPTION"
              caption="Description"
              cellRender={(cellData) => renderBoldCell(cellData)}
            />
            <Column
              dataField="TRANSACTIONAMOUNT"
              caption="Amount"
              cellRender={(cellData) => {
                const { displayValue, data } = cellData;
                const isTotalRow = data.TRANSACTIONTYPEDESCRIPTION === "Total";
                // Format amount as currency with dollar sign
                const formattedValue = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(displayValue);

                return (
                  <span
                    style={{
                      fontWeight: isTotalRow ? "bold" : "normal",
                      color: isTotalRow ? "#003399" : "inherit",
                    }}
                  >
                    {formattedValue}
                  </span>
                );
              }}
            />
            <Column
              dataField={"UNIQUEID"}
              caption={"Unique ID"}
              hidingPriority={7}
              allowEditing={true}
              visible={false}
            />
          </DataGrid>
        </div>
      </div>
    </>
  );
}

async function asyncValidation(bankAccountNumber, clientCode, segmentNumber) {
  const requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      sentBankAccountNumber: bankAccountNumber,
      sentSegment: segmentNumber,
      sentClientCode: clientCode,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/fetchThisClientSegment`;

  const response = await fetch(url, requestoptions);
  if (!response.ok) {
    throw new Error("System did not respond");
  }
  const data = await response.json();
  console.log("data from fetch", data.user_response.response);
  if (data.user_response.response === "ERROR") {
    return true;
  } else if (data.user_response.response === "OK") {
    return false;
  } else {
    throw new Error("Unexpected response");
  }
}

export default function ClientTransactions() {
  const { user } = useAuth();
  return <ClientTransactionsx clientCode={user.thisClientcode} />;
}
