import React, { useEffect, useState } from "react";
//import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
//import { getTransactionGroups } from "../../api/MyOwnServices";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Sorting,
  Editing,
  FilterRow,
  Item,
  Lookup,
  Popup,
  Form,
  MasterDetail,
  HeaderFilter,
  Search,
  SearchPanel,
  GroupItem,
} from "devextreme-react/data-grid";

import { TextBox } from "devextreme-react/text-box";

import "devextreme-react/text-area";
import "devextreme/data/data_source";
import { useAuth } from "../../contexts/auth.js";
//>>import "./app.scss";
import { mystore, getCFReportGroupData } from "./incomeStatementTypesData.js";

const allowedPageSizes = [8, 12, 20];

let pageoption = 90;

class IncomeStatementTypesx extends React.Component {
  constructor(props) {
    super(props);
    this.applyFilterTypes = [
      {
        key: "auto",
        name: "Immediately",
      },
      {
        key: "onClick",
        name: "On Button Click",
      },
    ];
    this.state = {
      //myClient: this.props.clientCode,
      currentRow: 0,
      filterValue: "90",
      selectedRowKeys: [],
      showFilterRow: true,
      showHeaderFilter: true,
      companyCode: 1,
      assetGroupsCodes: [],
      currentFilter: this.applyFilterTypes[0].key,
      isLoading: true, // Add a loading state
      ReportGroups: [],
    };
  }

  handleSelectionChanged(e) {
    this.setState({ selectedRowKeys: e.selectedRowKeys });
    if (e.selectedRowKeys.length > 0) {
      this.setState({ currentRow: e.selectedRowKeys[0] }); // update the current row
    }
  }

  handleEditingStart(e) {
    //console.log("Editing is starting for row", e.data);

    // You can access the data of the row that is being edited
    const rowToBeEdited = e.data;

    // Perform any checks or logic you want here.
    // For example, you might want to prevent editing if a certain condition is met:
    if (rowToBeEdited.someField === "someValue") {
      e.cancel = true; // Prevents the editing from starting
    }
  }

  componentDidMount() {
    getCFReportGroupData() // call the function to fetch data
      .then((data) => {
        console.log("data", data);
        this.setState({ ReportGroups: data.data }); // store the data in state
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
  }

  render() {
    return (
      <div className="content-block dx-card responsive-paddings">
        {/* <h3>Asset Types</h3> */}
        <DataGrid
          dataSource={mystore(this.state.companyCode)}
          keyExpr="UNIQUEID"
          showBorders={true}
          remoteOperations={false}
          onSelectionChanged={this.handleSelectionChanged.bind(this)} // add this line
          onEditingStart={this.handleEditingStart}
          allowColumnReordering={true}
          allowColumnResizing={true}
          //columnAutoWidth={true}
        >
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Paging enabled={true} />
          <Editing
            mode="popup"
            allowUpdating={true}
            allowAdding={true}
            allowDeleting={true}
          >
            <Popup
              title="Type Info"
              showTitle={true}
              width={900}
              height={850}
            />
            <Form>
              <Item
                itemType="group"
                colCount={2}
                colSpan={2}
                showBorders={true}
              >
                <Item dataField="REPORTGROUP" />
                <Item dataField="TYPE" />
                <Item dataField="DESCRIPTION" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={2}
                showBorders={true}
              >
                <Item dataField="REPORTINGSEQUENCE" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={2}
                showBorders={true}
              >
                <Item dataField="HEADING" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={2}
                showBorders={true}
              >
                <Item dataField="FOOTING" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={1}
                showBorders={true}
              >
                <Item dataField="COLUMNFOROWNER1" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={1}
                showBorders={true}
              >
                <Item dataField="COLUMNFOROWNER2" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={1}
                showBorders={true}
              >
                <Item dataField="PRINTDETAILROWS" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={1}
                showBorders={true}
              >
                <Item dataField="PRINTHEADING" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={1}
                showBorders={true}
              >
                <Item dataField="PRINTFOOTING" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={1}
                showBorders={true}
              >
                <Item dataField="PRINTOWNERONHEADING" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={2}
                showBorders={true}
              >
                <Item dataField="DONOTPRINTSECTION" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={2}
                showBorders={true}
              >
                <Item dataField="TOTALONLY" />
              </Item>
              <Item
                itemType="group"
                colCount={2}
                colSpan={1}
                showBorders={true}
              >
                {/* <Item dataField="TOTALLEVEL" /> */}

                <Item
                  dataField={"FORMULA"}
                  visible={true}
                  allowEditing={true}
                />
              </Item>
            </Form>
          </Editing>

          <Column dataField="REPORTGROUP" caption="Report Group">
            <Lookup
              dataSource={this.state.ReportGroups}
              valueExpr="REPORTGROUP"
              displayExpr="DESCRIPTION"
            />
          </Column>

          <Column dataField="TYPE" caption="Transaction Type" />

          <Column dataField="DESCRIPTION" caption="Description" width={150} />

          <Column dataField="REPORTINGSEQUENCE" caption="Report Sequence" />
          <Column
            dataField="COLUMNFOROWNER1"
            caption="Owner 1"
            dataType="boolean"
          />
          <Column
            dataField="COLUMNFOROWNER2"
            caption="Owner 2"
            dataType="boolean"
          />
          <Column
            dataField="PRINTDETAILROWS"
            caption="Details"
            dataType="boolean"
          />
          <Column dataField="HEADING" caption="Heading" />
          <Column
            dataField="PRINTHEADING"
            caption="Heading"
            dataType="boolean"
          />
          <Column dataField="FOOTING" caption="Footing" />
          <Column
            dataField="PRINTFOOTING"
            caption="Footer"
            dataType="boolean"
          />
          <Column
            dataField="PRINTOWNERONHEADING"
            caption="Client Name"
            dataType="boolean"
          />
          <Column
            dataField="DONOTPRINTSECTION"
            caption="Do not print"
            dataType="boolean"
          />
          {/* <Column dataField="TOTALLEVEL" caption="Total Level" /> */}
          <Column
            dataField="TOTALONLY"
            caption="Total Only"
            dataType="boolean"
          />
          <Column dataField="FORMULA" caption="Formula" />

          {/* <Column dataField={"UNIQUEID"} visible={false} allowEditing={false} /> */}
          <Paging defaultPageSize={12} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={allowedPageSizes}
          />
        </DataGrid>
      </div>
    );
  }
}
export default function AssetTypes() {
  const { user } = useAuth();
  //console.log({ user });
  return <IncomeStatementTypesx companyCode={user.companyCode} />;
}

// <Popup
// title="Type Info"
// showTitle={true}
// width={900}
// height={800}
// />
// <Form>
// <Item itemType="group" colCount={2} colSpan={2}>
//   <Item dataField="ASSETTYPEGROUP" />
//   <Item dataField="DESCRIPTION" />
//   <Item dataField="REPORTSEQUENCE" />
//   <Item dataField="SHOWASSETGROUPTOTALSINNETASSETSHEET" />
// </Item>
// </Form>
