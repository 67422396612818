import React, { useState, useEffect, useCallback } from "react";
//>>import "./profile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "devextreme-react/text-area";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Form, {
  Item,
  ButtonItem,
  GroupItem,
  Lookup,
  EmptyItem,
} from "devextreme-react/form";
import SelectBox from "devextreme-react/select-box";
//import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import {
  fetchThisClientData,
  GenerateAccountStatementData,
} from "../clientManagementData";
import { updateInterest, resetInterest } from "./interestData";
import ClientUnpostedInterestTransactions from "./clientUnpostedInterestTransactions";
import ClientPostedInterestTransactions from "./clientPostedInterestTransactions";

import { getBanks } from "./clientBanksAccountsData";
//import ClientInterestTransactions from "./clientInterestTransactions";

const AccountStatementx = (props) => {
  //console.log(props);

  const [isLoading, setIsLoading] = useState(false);

  const [myClientCode, setClientCode] = useState(props.sentClientCode);
  const [bankAccountList, setBankAccountList] = useState([]);
  const MySwal = withReactContent(Swal);

  //const { register, handleSubmit, errors } = useForm();

  const [currentBankAccount, setCurrentBankAccount] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);

  const [Segment, setSegment] = useState("");
  const [startYear, setStartdate] = useState("");
  const [endYear, setEnddate] = useState("");
  const [allAccounts, setAllAccounts] = useState(false);
  const [monthYearList, setMonthYearList] = useState([]);
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");
  const [showInterest, setShowInterest] = useState(false);
  const [filename, setFilename] = useState(""); // State to store the generated filename

  const handleBankAccountChange = (e) => {
    setCurrentBankAccount(e.value);
    setRefreshKey((prevKey) => prevKey + 1);
    console.log("reset the refesh key", refreshKey);
  };

  useEffect(() => {
    console.log("client code value", myClientCode);
    fetchThisClientData(myClientCode).then((data) => {
      console.log("client data", data);
      //setStartdate(data.STARTDATE);
      //setEnddate(data.ENDDATE);

      setStartPeriod(data.startinterestperiod);
      setEndPeriod(data.endinterestperiod);
    });
    getBanks(myClientCode) // call the function to fetch data
      .then((data) => {
        //console.log("bank accounts ", data.data);
        setBankAccountList(data.data);
        //console.log("bank accounts ", bankAccountList);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
    generateMonthYearList();
    //console.log("month year list outside", monthYearList);
  }, []);

  const generateMonthYearList = () => {
    const monthYears = [];
    for (let year = 2030; year >= 2020; year--) {
      for (let month = 12; month >= 1; month--) {
        monthYears.push(`${year}-${month.toString().padStart(2, "0")}`);
      }
    }
    setMonthYearList(monthYears);
    return monthYears;
  };

  const handleSegmentChange = (e) => {
    setSegment(e.value);
  };

  //   const ShowInterestTransactions = () => {
  //     setShowInterest((currentShowInterest) => !currentShowInterest);
  //   };

  //   const handleMappingUpdated = () => {
  //     setShowInterest(false);
  //     // Do something with the value, like updating the state
  //   };

  const GenerateAccountStatement = async (e) => {
    try {
      setIsLoading(true);
      const result = await GenerateAccountStatementData(
        myClientCode,
        currentBankAccount,
        Segment,
        startPeriod,
        endPeriod,
        allAccounts
      );
      setFilename(result.filename);
      console.log("File created - ", filename, " now send it");
      //const response = await GenerateExcelFileForSend(myClientCode);
      // console.log("File received");

      // if (response && response.filename) {
      //   setFilename(response.filename); // Save the filename to state
      // }

      MySwal.fire({
        icon: "success",
        title: "Completed",
        text: "Carrying charges have been successfully generated.",
      });
    } catch (error) {
      console.error("Error processing interest:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue processing the interest.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("client code value", myClientCode);
    fetchThisClientData(myClientCode).then((data) => {
      console.log("client data", data);
      setStartPeriod(data.startinterestperiod);
      setEndPeriod(data.endinterestperiod);
    });
  }, [myClientCode]);

  const downloadFile = (filename) => {
    const url = `${process.env.REACT_APP_BASE_URL_AXIOS}/download/${filename}`;
    //const url = `http://localhost:4000/download/${filename}`;
    console.log("url is: ", url);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setFilename(""); // Clear the filename after download
  };

  return (
    <>
      <p>&nbsp;&nbsp;&nbsp;Account Statement</p>
      <div className="red-color responsive-paddingsx">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <label>
            Bank Account:
            <SelectBox
              className="white-text-selectbox"
              style={{ width: "250px", height: "30px", marginTop: "5px" }}
              items={bankAccountList}
              valueExpr="BANKACCOUNTNUMBER"
              displayExpr={(item) =>
                item
                  ? `${item.BANKNAME} - ${item.BANKACCOUNTNUMBER} - ${item.ACCOUNTDESCRIPTION}`
                  : ""
              }
              value={currentBankAccount}
              showClearButton={true}
              onValueChanged={handleBankAccountChange}
            />
          </label>
          <label>
            Segment:
            <TextBox
              value={Segment}
              onValueChanged={handleSegmentChange}
              className="white-text-selectbox"
              style={{ width: "50px", height: "30px", marginTop: "5px" }}
            />
          </label>
          <label>
            Period:
            <SelectBox
              className="white-text-selectbox"
              style={{ width: "150px", height: "30px", marginTop: "5px" }}
              items={monthYearList}
              value={startPeriod}
              acceptCustomValue={true}
              onValueChanged={(e) => setStartPeriod(e.value)}
            />
          </label>
          <label>
            End Period:
            <SelectBox
              className="white-text-selectbox"
              style={{ width: "150px", height: "30px", marginTop: "5px" }}
              items={monthYearList}
              value={endPeriod}
              acceptCustomValue={true}
              onValueChanged={(e) => setEndPeriod(e.value)}
            />
          </label>

          <label>
            All Accounts:
            <div>
              <input
                style={{
                  width: "50px",
                  height: "20px",
                  marginTop: "5px",
                  marginRight: "5px",
                }}
                type="checkbox"
                value={allAccounts}
                onChange={(e) => setAllAccounts(e.target.checked)}
              />
            </div>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "34%",
          }}
        >
          <Button
            text="Generate Statement"
            onClick={GenerateAccountStatement}
            style={{ width: "250px", height: "30px", marginTop: "30px" }}
          ></Button>
        </div>
      </div>
      {filename && (
        <div className="red-color responsive-paddingsx">
          <h3>File Download is ready</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <Button
              text="Download Account Statement"
              onClick={() => downloadFile(filename)}
              style={{ width: "350px", height: "30px", marginTop: "30px" }}
            ></Button>
          </div>
        </div>
      )}

      {isLoading && (
        <div
          className="spinner-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh", // Full height of the viewport for proper vertical centering
          }}
        >
          <p style={{ marginBottom: "100px", fontSize: "24px" }}>
            Processing, please wait &nbsp;&nbsp;
          </p>
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            className="large-spinner"
            style={{ fontSize: "48px" }} // Larger spinner size
          />
        </div>
      )}
    </>
  );
};

export default function AccountStatement(props) {
  //const { user } = useAuth();
  //console.log("props coming in", props);
  return <AccountStatementx sentClientCode={props.clientCode} />;
}
