import React from "react";

import { useAuth } from "../../../contexts/auth";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Export,
} from "devextreme-react/data-grid";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { mystore7 } from "./segmentData";
import "devextreme-react/text-area";
import "devextreme/data/data_source";
import "./debtSummaryStyles.scss";
import "./debtsummary.css";
import { GenerateNetWorthExcel } from "./generateNetWorthExcel";
//import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
import html2canvas from "html2canvas";

const renderDescriptionCell = (data) => {
  const { data: rowData } = data;
  console.log("Type", rowData.LINETYPE, "DESC", rowData.DESCRIPTION);
  let style = {};
  if (rowData.LINETYPE === "H") {
    style = {
      color: "white",
      backgroundColor: "black",
      borderTop: "1px solid black",

      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.LINETYPE === "S") {
    style = {
      color: "black",
      backgroundColor: "white",
      borderTop: "1px solid black",
      //borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
      fontWeight: "600",
    };
  } else if (rowData.LINETYPE === "X") {
    style = {
      color: "blue",
      backgroundColor: "lightgrey",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.LINETYPE === "T") {
    style = {
      color: "black",
      backgroundColor: "lightgrey",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
      fontWeight: "600",
    };
  } else if (rowData.LINETYPE === "Z") {
    style = {
      color: "black",

      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else {
    style = {
      backgroundColor: "",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  }

  return <div style={style}>{rowData.DESCRIPTION}</div>;
};

const renderValueFieldCell = (data) => renderValueFieldCellCombined(data, 1);
const renderValueFieldCell2 = (data) => renderValueFieldCellCombined(data, 2);
const renderValueFieldCell3 = (data) => renderValueFieldCellCombined(data, 3);

const renderValueFieldCellCombined = (data, column) => {
  const { data: rowData } = data;

  let style = { borderRight: "1px solid black" };
  let formattedValue = "";
  let displayValue = "";
  let printColumn = "";
  let valueField = "";
  let stringField = "";

  switch (column) {
    case 1:
      printColumn = rowData.PRINTCOLUMN1;
      valueField = rowData.VALUEFIELD;
      stringField = rowData.FIRSTSTRING;
      break;
    case 2:
      printColumn = rowData.PRINTCOLUMN2;
      valueField = rowData.VALUEFIELD2;
      stringField = rowData.SECONDSTRING;
      break;
    case 3:
      printColumn = rowData.PRINTCOLUMN3;
      valueField = rowData.VALUEFIELD3;
      stringField = rowData.THIRDSTRING;
      break;
    default:
  }

  if (rowData.LINETYPE === "T" || rowData.LINETYPE === "X") {
    style = {
      color: "black",
      backgroundColor: "#D9D9D9",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      fontWeight: "600",
    };
  } else if (rowData.LINETYPE === "H") {
    style = {
      backgroundColor: "#E6D180",
      borderRight: "1px solid black",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      fontWeight: "600",
    };
  } else if (rowData.LINETYPE === "Z") {
    style = {
      color: "black",

      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      fontWeight: "600",
    };
  }

  if ((printColumn === "N") | (printColumn === "S")) {
    style = {};
  }

  const isNegative = valueField < 0;
  const absoluteValue = Math.abs(valueField);

  formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(absoluteValue);

  displayValue = isNegative ? `(${formattedValue})` : formattedValue;

  if (
    (rowData.LINETYPE === "H" ||
      rowData.LINETYPE === "X" ||
      rowData.LINETYPE === "B") &&
    printColumn !== "Y" &&
    printColumn !== "H"
  ) {
    return <div style={style}>&nbsp;</div>;
  } else if (printColumn === "H") {
    if (stringField === "") {
      return <div style={style}>&nbsp;</div>;
    } else {
      return <div style={{ ...style, textAlign: "center" }}>{stringField}</div>;
    }
  } else {
    return <div style={style}>{displayValue}&nbsp;&nbsp;</div>;
  }
};

class NetWorthx extends React.Component {
  constructor(props) {
    super(props);
    this.applyFilterTypes = [
      {
        key: "auto",
        name: "Immediately",
      },
      {
        key: "onClick",
        name: "On Button Click",
      },
    ];
    this.state = {
      currentRow: 0,
      filterValue: "90",
      selectedRowKeys: [],
      showFilterRow: false,
      showHeaderFilter: false,
      companyCode: 1,
      assetGroupsCodes: [],
      currentFilter: this.applyFilterTypes[0].key,
      isLoading: false,
      thisWidth: 700,
      showCurrentOnly: this.props.showPrior,
      EditExcelOn: false,
      ShowCreateClientPresentation: this.props.ShowCreateClientPresentation,
    };
    console.log("what is props in debtsummary :", { props });
  }

  handleMappingUpdated2 = (value) => {
    this.setState({ EditExcelOn: false });
  };

  CreateExcel = () => {
    console.log("Edit Batch Clicked");
    this.setState({ EditExcelOn: true });
  };

  handleSelectionChanged(e) {
    this.setState({ selectedRowKeys: e.selectedRowKeys });
    if (e.selectedRowKeys.length > 0) {
      this.setState({ currentRow: e.selectedRowKeys[0] });
    }
  }

  handleEditingStart(e) {
    console.log("Editing is starting for row", e.data);

    const rowToBeEdited = e.data;

    if (rowToBeEdited.someField === "someValue") {
      e.cancel = true;
    }
  }

  onCellPrepared = (e) => {
    e.cellElement.style.padding = "0px";
  };

  onRowPrepared(e) {}

  generatePDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    doc.setFontSize(7);

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGridRef.instance,
      customizeCell: ({ gridCell, pdfCell }) => {
        pdfCell.fontSize = 7;
        pdfCell.borderColor = "#FFFFFF";

        if (gridCell.rowType === "data") {
          if (gridCell.data.LINETYPE === "H") {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#000000";
            pdfCell.textColor = "#FFFFFF";
          } else if (gridCell.data.LINETYPE === "X") {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#D9D9D9";
            pdfCell.textColor = "#000000";
          } else if (gridCell.data.LINETYPE === "T") {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#D9D9D9";
            pdfCell.textColor = "#000000";
          } else if (gridCell.data.DESCRIPTION === "") {
            pdfCell.text = "";
          } else {
            pdfCell.borderColor = null;
          }
          pdfCell.borderColor = "#FFFFFF";
        }
      },
    }).then(() => {
      doc.save("net_worth_summary.pdf");
    });
  };

  render() {
    return (
      <>
        <style>
          {`
          .dx-datagrid-rowsview .dx-row {
            border: none !important;
          }

          .dx-datagrid-headers .dx-header-row {
            border: none !important;
          }

          .dx-datagrid-content .dx-cell {
            border: none !important;
          }
        `}
        </style>

        {this.state.isLoading && (
          <div className="spinner-container">
            {this.state.isLoading && (
              <>
                <p>Building the Excel File please wait &nbsp;&nbsp;</p>
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  className="large-spinner"
                />
              </>
            )}
          </div>
        )}
        {this.state.EditExcelOn !== true && (
          <>
            <div>
              <h3 style={{ fontWeight: "bold", marginLeft: "20px" }}>
                Net Worth
              </h3>
            </div>

            {this.state.ShowCreateClientPresentation !== true && (
              <>
                <Button
                  text="Create Excel"
                  onClick={this.CreateExcel}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
                <Button
                  text="Generate PDF"
                  onClick={this.generatePDF}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
              </>
            )}

            <div className="content-block2 dx-card responsive-paddings">
              <div
              // className="custom-networth-container4"
              // style={{ height: "850px" }}
              >
                <DataGrid
                  dataSource={mystore7(
                    this.props.clientCode,
                    this.props.ShowCreateClientPresentation
                  )}
                  onRowPrepared={this.onRowPrepared}
                  onCellPrepared={this.onCellPrepared}
                  //scrolling={{ mode: "virtual" }}
                  showBorders={false}
                  showRowLines={false}
                  remoteOperations={false}
                  onSelectionChanged={this.handleSelectionChanged.bind(this)}
                  onEditingStart={this.handleEditingStart}
                  width={"37%"}
                  height={"100%"}
                  ref={(ref) => (this.dataGridRef = ref)}
                >
                  {/* <Export enabled={true} /> */}
                  <FilterRow
                    visible={this.state.showFilterRow}
                    applyFilter={this.state.currentFilter}
                  />
                  <HeaderFilter visible={this.state.showHeaderFilter} />
                  <Paging enabled={false} />
                  <Column
                    dataField="UNIQUEID"
                    caption="Unique ID"
                    visible={false}
                  />
                  <Column dataField="ROWNUMBER" caption="Row" visible={false} />

                  <Column
                    dataField="DESCRIPTION"
                    caption={`Net Worth for ${this.props.clientCode}`}
                    width={350}
                    visible={true}
                    cellRender={renderDescriptionCell}
                  />
                  <Column
                    dataField="VALUEFIELD"
                    caption=""
                    format={"$###,###,###"}
                    alignment="right"
                    cellRender={renderValueFieldCell}
                    width={100}
                  />
                  <Column
                    dataField="VALUEFIELD1"
                    caption=""
                    format={"$###,###,###"}
                    alignment="right"
                    cellRender={renderValueFieldCell2}
                    width={100}
                  />
                  <Column
                    dataField="VALUEFIELD2"
                    caption=""
                    format={"$###,###,###"}
                    alignment="right"
                    cellRender={renderValueFieldCell3}
                    width={100}
                  />
                  <Column
                    dataField="FORMULAFIELD"
                    caption="Formula"
                    visible={false}
                  />
                  <Column dataField="LINETYPE" caption="Type" visible={false} />
                </DataGrid>
              </div>
            </div>
          </>
        )}
        {this.state.EditExcelOn === true && (
          <div>
            <GenerateNetWorthExcel
              clientCode={this.props.clientCode}
              onMappingUpdated2={this.handleMappingUpdated2}
            />
          </div>
        )}
      </>
    );
  }
}

export default function NetWorth(props) {
  const { user } = useAuth();
  console.log("what is props in progress summary not x :", { props });
  return (
    <NetWorthx
      clientCode={user.thisClientcode}
      thisWidth={props.thisWidth}
      showPrior={props.showPrior}
      ShowCreateClientPresentation={props.ShowCreateClientPresentation}
    />
  );
}
